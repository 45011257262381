<template>
  <div>
    <GlobalHeader /> 
    <router-view/>
    <GlobalFooter /> 
  </div>
  
</template>
<script>
import GlobalHeader from './components/GlobalHeader.vue'
import GlobalFooter from './components/GlobalFooter.vue'
export default {
  components: {
    GlobalHeader,
    GlobalFooter
  }
}
</script>
<style>
* {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

body {
  background: #fff;
  padding-top: 7rem;
  color: #767d85;
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  font-size: 15pt;
  font-weight: 200;
  line-height: 2;
}

ul {
  list-style: none;
  margin: 0 0 2em 0;
  padding-left: 1em;
}

</style>
